export const PACKAGE = { name: '@mui/styled-engine', version: '6.1.10' };
export * from '@mui/styled-engine';
export { internal_mutateStyles as mutateStyles } from '@mui/styled-engine';
export { internal_serializeStyles as serializeStyles } from '@mui/styled-engine';
import * as styledEngine from '@mui/styled-engine';

            const resolveImport = (itemName, subItemName) => {
                const item = styledEngine[itemName];
                const subItem = styledEngine[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('GlobalStyles', 'default');
resolveImport('StyledEngineProvider', 'default');