export const PACKAGE = { name: '@mui/lab', version: '6.0.0-beta.18' };
export * from '@mui/lab';
export * as 'AdapterDateFns' from '@mui/lab/AdapterDateFns/index.js';
export * as 'AdapterDayjs' from '@mui/lab/AdapterDayjs/index.js';
export * as 'AdapterLuxon' from '@mui/lab/AdapterLuxon/index.js';
export * as 'AdapterMoment' from '@mui/lab/AdapterMoment/index.js';
export * as 'themeAugmentation' from '@mui/lab/themeAugmentation/index.js';
export * as 'zero-styled' from '@mui/lab/zero-styled/index.js';
import * as lab from '@mui/lab';

            const resolveImport = (itemName, subItemName) => {
                const item = lab[itemName];
                const subItem = lab[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('Alert', 'default');
resolveImport('AlertTitle', 'default');
resolveImport('Autocomplete', 'default');
resolveImport('AvatarGroup', 'default');
resolveImport('CalendarPicker', 'default');
resolveImport('CalendarPicker', 'calendarPickerClasses');
resolveImport('CalendarPickerSkeleton', 'default');
resolveImport('CalendarPickerSkeleton', 'calendarPickerSkeletonClasses');
resolveImport('CalendarPickerSkeleton', 'getCalendarPickerSkeletonUtilityClass');
resolveImport('ClockPicker', 'default');
resolveImport('ClockPicker', 'clockPickerClasses');
resolveImport('DatePicker', 'default');
resolveImport('DateRangePicker', 'default');
resolveImport('DateRangePickerDay', 'default');
resolveImport('DateRangePickerDay', 'getDateRangePickerDayUtilityClass');
resolveImport('DateTimePicker', 'default');
resolveImport('DesktopDatePicker', 'default');
resolveImport('DesktopDateRangePicker', 'default');
resolveImport('DesktopDateTimePicker', 'default');
resolveImport('DesktopTimePicker', 'default');
resolveImport('LoadingButton', 'default');
resolveImport('LoadingButton', 'loadingButtonClasses');
resolveImport('LoadingButton', 'getLoadingButtonUtilityClass');
resolveImport('LocalizationProvider', 'default');
resolveImport('Masonry', 'default');
resolveImport('Masonry', 'masonryClasses');
resolveImport('Masonry', 'getMasonryUtilityClass');
resolveImport('MobileDatePicker', 'default');
resolveImport('MobileDateRangePicker', 'default');
resolveImport('MobileDateTimePicker', 'default');
resolveImport('MobileTimePicker', 'default');
resolveImport('MonthPicker', 'default');
resolveImport('MonthPicker', 'getMonthPickerUtilityClass');
resolveImport('MonthPicker', 'monthPickerClasses');
resolveImport('Pagination', 'default');
resolveImport('Pagination', 'usePagination');
resolveImport('PaginationItem', 'default');
resolveImport('PickersDay', 'default');
resolveImport('PickersDay', 'getPickersDayUtilityClass');
resolveImport('PickersDay', 'pickersDayClasses');
resolveImport('Rating', 'default');
resolveImport('Skeleton', 'default');
resolveImport('SpeedDial', 'default');
resolveImport('SpeedDialAction', 'default');
resolveImport('SpeedDialIcon', 'default');
resolveImport('StaticDatePicker', 'default');
resolveImport('StaticDateRangePicker', 'default');
resolveImport('StaticDateTimePicker', 'default');
resolveImport('StaticTimePicker', 'default');
resolveImport('TabContext', 'default');
resolveImport('TabContext', 'getPanelId');
resolveImport('TabContext', 'getTabId');
resolveImport('TabContext', 'useTabContext');
resolveImport('TabList', 'default');
resolveImport('TabPanel', 'default');
resolveImport('TabPanel', 'tabPanelClasses');
resolveImport('TabPanel', 'getTabPanelUtilityClass');
resolveImport('TimePicker', 'default');
resolveImport('Timeline', 'default');
resolveImport('Timeline', 'timelineClasses');
resolveImport('Timeline', 'getTimelineUtilityClass');
resolveImport('TimelineConnector', 'default');
resolveImport('TimelineConnector', 'timelineConnectorClasses');
resolveImport('TimelineConnector', 'getTimelineConnectorUtilityClass');
resolveImport('TimelineContent', 'default');
resolveImport('TimelineContent', 'timelineContentClasses');
resolveImport('TimelineContent', 'getTimelineContentUtilityClass');
resolveImport('TimelineDot', 'default');
resolveImport('TimelineDot', 'timelineDotClasses');
resolveImport('TimelineDot', 'getTimelineDotUtilityClass');
resolveImport('TimelineItem', 'default');
resolveImport('TimelineItem', 'timelineItemClasses');
resolveImport('TimelineItem', 'getTimelineItemUtilityClass');
resolveImport('TimelineOppositeContent', 'default');
resolveImport('TimelineOppositeContent', 'timelineOppositeContentClasses');
resolveImport('TimelineOppositeContent', 'getTimelineOppositeContentUtilityClass');
resolveImport('TimelineSeparator', 'default');
resolveImport('TimelineSeparator', 'timelineSeparatorClasses');
resolveImport('TimelineSeparator', 'getTimelineSeparatorUtilityClass');
resolveImport('ToggleButton', 'default');
resolveImport('ToggleButtonGroup', 'default');
resolveImport('TreeItem', 'default');
resolveImport('TreeView', 'default');
resolveImport('YearPicker', 'default');
resolveImport('YearPicker', 'getYearPickerUtilityClass');
resolveImport('YearPicker', 'yearPickerClasses');
resolveImport('useAutocomplete', 'createFilterOptions');
resolveImport('useAutocomplete', 'default');