export const PACKAGE = { name: '@mui/base', version: '5.0.0-beta.64' };
export * from '@mui/base';
export { unstable_generateUtilityClass as generateUtilityClass } from '@mui/base';
export { unstable_isGlobalState as isGlobalState } from '@mui/base';
export { unstable_composeClasses as composeClasses } from '@mui/base';
export { unstable_useNumberInput as useNumberInput } from '@mui/base';
export { unstable_useModal as useModal } from '@mui/base';
export * as 'ClassNameGenerator' from '@mui/base/ClassNameGenerator/index.js';
export * as 'MultiSelect' from '@mui/base/MultiSelect/index.js';
export * as 'Transitions' from '@mui/base/Transitions/index.js';
export * as 'generateUtilityClasses' from '@mui/base/generateUtilityClasses/index.js';
export * as 'useCompound' from '@mui/base/useCompound/index.js';
export * as 'useList' from '@mui/base/useList/index.js';
export * as 'useTransition' from '@mui/base/useTransition/index.js';
export * as 'utils' from '@mui/base/utils/index.js';
import * as base from '@mui/base';

            const resolveImport = (itemName, subItemName) => {
                const item = base[itemName];
                const subItem = base[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('ClickAwayListener', 'ClickAwayListener');
resolveImport('Dropdown', 'Dropdown');
resolveImport('FocusTrap', 'FocusTrap');
resolveImport('NoSsr', 'NoSsr');
resolveImport('Popper', 'Popper');
resolveImport('Popper', 'getPopperUtilityClass');
resolveImport('Popper', 'popperClasses');
resolveImport('Portal', 'Portal');
resolveImport('TextareaAutosize', 'TextareaAutosize');
resolveImport('unstable_generateUtilityClass', 'generateUtilityClass');
resolveImport('unstable_generateUtilityClass', 'isGlobalState');
resolveImport('Badge', 'Badge');
resolveImport('Badge', 'badgeClasses');
resolveImport('Badge', 'getBadgeUtilityClass');
resolveImport('Button', 'Button');
resolveImport('Button', 'buttonClasses');
resolveImport('Button', 'getButtonUtilityClass');
resolveImport('unstable_composeClasses', 'unstable_composeClasses');
resolveImport('FormControl', 'FormControl');
resolveImport('FormControl', 'FormControlContext');
resolveImport('FormControl', 'useFormControlContext');
resolveImport('FormControl', 'formControlClasses');
resolveImport('FormControl', 'getFormControlUtilityClass');
resolveImport('Input', 'Input');
resolveImport('Input', 'getInputUtilityClass');
resolveImport('Input', 'inputClasses');
resolveImport('Menu', 'Menu');
resolveImport('Menu', 'getMenuUtilityClass');
resolveImport('Menu', 'menuClasses');
resolveImport('MenuButton', 'MenuButton');
resolveImport('MenuButton', 'getMenuButtonUtilityClass');
resolveImport('MenuButton', 'menuButtonClasses');
resolveImport('MenuItem', 'MenuItem');
resolveImport('MenuItem', 'getMenuItemUtilityClass');
resolveImport('MenuItem', 'menuItemClasses');
resolveImport('Modal', 'Modal');
resolveImport('Modal', 'getModalUtilityClass');
resolveImport('Modal', 'modalClasses');
resolveImport('Unstable_NumberInput', 'Unstable_NumberInput');
resolveImport('Unstable_NumberInput', 'getNumberInputUtilityClass');
resolveImport('Unstable_NumberInput', 'numberInputClasses');
resolveImport('OptionGroup', 'OptionGroup');
resolveImport('OptionGroup', 'getOptionGroupUtilityClass');
resolveImport('OptionGroup', 'optionGroupClasses');
resolveImport('Option', 'Option');
resolveImport('Option', 'getOptionUtilityClass');
resolveImport('Option', 'optionClasses');
resolveImport('Unstable_Popup', 'Unstable_Popup');
resolveImport('Unstable_Popup', 'getPopupUtilityClass');
resolveImport('Unstable_Popup', 'popupClasses');
resolveImport('Unstable_Popup', 'PopupContext');
resolveImport('Select', 'Select');
resolveImport('Select', 'getSelectUtilityClass');
resolveImport('Select', 'selectClasses');
resolveImport('Slider', 'Slider');
resolveImport('Slider', 'getSliderUtilityClass');
resolveImport('Slider', 'sliderClasses');
resolveImport('Snackbar', 'Snackbar');
resolveImport('Snackbar', 'getSnackbarUtilityClass');
resolveImport('Snackbar', 'snackbarClasses');
resolveImport('Switch', 'Switch');
resolveImport('Switch', 'getSwitchUtilityClass');
resolveImport('Switch', 'switchClasses');
resolveImport('TablePagination', 'TablePagination');
resolveImport('TablePagination', 'TablePaginationActions');
resolveImport('TablePagination', 'getTablePaginationUtilityClass');
resolveImport('TablePagination', 'tablePaginationClasses');
resolveImport('TabPanel', 'TabPanel');
resolveImport('TabPanel', 'getTabPanelUtilityClass');
resolveImport('TabPanel', 'tabPanelClasses');
resolveImport('TabsList', 'TabsList');
resolveImport('TabsList', 'getTabsListUtilityClass');
resolveImport('TabsList', 'tabsListClasses');
resolveImport('Tabs', 'Tabs');
resolveImport('Tabs', 'TabsContext');
resolveImport('Tabs', 'useTabsContext');
resolveImport('Tabs', 'getTabsUtilityClass');
resolveImport('Tabs', 'tabsClasses');
resolveImport('Tab', 'Tab');
resolveImport('Tab', 'getTabUtilityClass');
resolveImport('Tab', 'tabClasses');
resolveImport('useAutocomplete', 'createFilterOptions');
resolveImport('useAutocomplete', 'useAutocomplete');
resolveImport('useBadge', 'useBadge');
resolveImport('useButton', 'useButton');
resolveImport('useDropdown', 'useDropdown');
resolveImport('useDropdown', 'DropdownActionTypes');
resolveImport('useDropdown', 'DropdownContext');
resolveImport('useInput', 'useInput');
resolveImport('useMenu', 'useMenu');
resolveImport('useMenu', 'MenuProvider');
resolveImport('useMenuButton', 'useMenuButton');
resolveImport('useMenuItem', 'useMenuItem');
resolveImport('useMenuItem', 'useMenuItemContextStabilizer');
resolveImport('useOption', 'useOption');
resolveImport('useOption', 'useOptionContextStabilizer');
resolveImport('useSelect', 'useSelect');
resolveImport('useSelect', 'SelectActionTypes');
resolveImport('useSelect', 'SelectProvider');
resolveImport('useSlider', 'Identity');
resolveImport('useSlider', 'useSlider');
resolveImport('useSlider', 'valueToPercent');
resolveImport('useSnackbar', 'useSnackbar');
resolveImport('useSwitch', 'useSwitch');
resolveImport('useTab', 'useTab');
resolveImport('useTabPanel', 'useTabPanel');
resolveImport('useTabs', 'useTabs');
resolveImport('useTabs', 'TabsProvider');
resolveImport('useTabsList', 'useTabsList');
resolveImport('useTabsList', 'TabsListActionTypes');
resolveImport('useTabsList', 'TabsListProvider');