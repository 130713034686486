export const PACKAGE = { name: '@mui/system', version: '6.1.10' };
export * from '@mui/system';
export { unstable_createCssVarsProvider as createCssVarsProvider } from '@mui/system';
export { unstable_createCssVarsTheme as createCssVarsTheme } from '@mui/system';
export { unstable_createGetCssVar as createGetCssVar } from '@mui/system';
export { unstable_createStyleFunctionSx as createStyleFunctionSx } from '@mui/system';
export { unstable_cssVarsParser as cssVarsParser } from '@mui/system';
export { unstable_defaultSxConfig as defaultSxConfig } from '@mui/system';
export { unstable_extendSxProp as extendSxProp } from '@mui/system';
export { unstable_getThemeValue as getThemeValue } from '@mui/system';
export { unstable_memoTheme as memoTheme } from '@mui/system';
export { unstable_prepareCssVars as prepareCssVars } from '@mui/system';
export { unstable_resolveBreakpointValues as resolveBreakpointValues } from '@mui/system';
export { unstable_styleFunctionSx as styleFunctionSx } from '@mui/system';
export { unstable_traverseBreakpoints as traverseBreakpoints } from '@mui/system';
export * as 'DefaultPropsProvider' from '@mui/system/DefaultPropsProvider/index.js';
export * as 'InitColorSchemeScript' from '@mui/system/InitColorSchemeScript/index.js';
export * as 'colorManipulator' from '@mui/system/colorManipulator/index.js';
export * as 'cssGrid' from '@mui/system/cssGrid/index.js';
export * as 'cssVars' from '@mui/system/cssVars/index.js';
export * as 'memoize' from '@mui/system/memoize/index.js';
export * as 'merge' from '@mui/system/merge/index.js';
export * as 'preprocessStyles' from '@mui/system/preprocessStyles.js';
export * as 'propsToClassKey' from '@mui/system/propsToClassKey/index.js';
import * as system from '@mui/system';

            const resolveImport = (itemName, subItemName) => {
                const item = system[itemName];
                const subItem = system[subItemName];
                if (item && subItem) {
                    try {
                        if (!item.hasOwnProperty(subItemName)) {
                            item[subItemName] = subItem;

                            const prefixes = [ 'internal_', 'unstable_' ];
                            for (let prefix of prefixes) {
                                if (subItemName.startsWith(prefix)) {
                                    const newName = subItemName.slice(prefix.length);
                                    item[newName] = subItem;
                                }
                            }
                        }
                    } catch {}
                }
            };
        
resolveImport('Box', 'boxClasses');
resolveImport('Box', 'default');
resolveImport('Container', 'containerClasses');
resolveImport('Container', 'default');
resolveImport('Container', 'getContainerUtilityClass');
resolveImport('GlobalStyles', 'default');
resolveImport('Grid', 'createGrid');
resolveImport('Grid', 'default');
resolveImport('Grid', 'gridClasses');
resolveImport('Grid', 'unstable_traverseBreakpoints');
resolveImport('Grid', 'getGridUtilityClass');
resolveImport('RtlProvider', 'default');
resolveImport('RtlProvider', 'useRtl');
resolveImport('Stack', 'createStack');
resolveImport('Stack', 'default');
resolveImport('Stack', 'stackClasses');
resolveImport('Stack', 'getStackUtilityClass');
resolveImport('ThemeProvider', 'default');
resolveImport('borders', 'default');
resolveImport('borders', 'border');
resolveImport('borders', 'borderBottom');
resolveImport('borders', 'borderBottomColor');
resolveImport('borders', 'borderColor');
resolveImport('borders', 'borderLeft');
resolveImport('borders', 'borderLeftColor');
resolveImport('borders', 'borderRadius');
resolveImport('borders', 'borderRight');
resolveImport('borders', 'borderRightColor');
resolveImport('borders', 'borderTop');
resolveImport('borders', 'borderTopColor');
resolveImport('borders', 'borderTransform');
resolveImport('borders', 'outline');
resolveImport('borders', 'outlineColor');
resolveImport('breakpoints', 'default');
resolveImport('breakpoints', 'computeBreakpointsBase');
resolveImport('breakpoints', 'createEmptyBreakpointObject');
resolveImport('breakpoints', 'handleBreakpoints');
resolveImport('breakpoints', 'mergeBreakpointsInOrder');
resolveImport('breakpoints', 'removeUnusedBreakpoints');
resolveImport('breakpoints', 'resolveBreakpointValues');
resolveImport('breakpoints', 'values');
resolveImport('compose', 'default');
resolveImport('createBox', 'default');
resolveImport('createBreakpoints', 'unstable_createBreakpoints');
resolveImport('createStyled', 'default');
resolveImport('createStyled', 'shouldForwardProp');
resolveImport('createStyled', 'systemDefaultTheme');
resolveImport('createTheme', 'default');
resolveImport('createTheme', 'private_createBreakpoints');
resolveImport('createTheme', 'unstable_applyStyles');
resolveImport('cssContainerQueries', 'default');
resolveImport('cssContainerQueries', 'getContainerQuery');
resolveImport('cssContainerQueries', 'isCqShorthand');
resolveImport('cssContainerQueries', 'sortContainerQueries');
resolveImport('display', 'default');
resolveImport('display', 'displayPrint');
resolveImport('display', 'displayRaw');
resolveImport('display', 'overflow');
resolveImport('display', 'textOverflow');
resolveImport('display', 'visibility');
resolveImport('display', 'whiteSpace');
resolveImport('flexbox', 'default');
resolveImport('flexbox', 'alignContent');
resolveImport('flexbox', 'alignItems');
resolveImport('flexbox', 'alignSelf');
resolveImport('flexbox', 'flex');
resolveImport('flexbox', 'flexBasis');
resolveImport('flexbox', 'flexDirection');
resolveImport('flexbox', 'flexGrow');
resolveImport('flexbox', 'flexShrink');
resolveImport('flexbox', 'flexWrap');
resolveImport('flexbox', 'justifyContent');
resolveImport('flexbox', 'justifyItems');
resolveImport('flexbox', 'justifySelf');
resolveImport('flexbox', 'order');
resolveImport('palette', 'default');
resolveImport('palette', 'backgroundColor');
resolveImport('palette', 'bgcolor');
resolveImport('palette', 'color');
resolveImport('palette', 'paletteTransform');
resolveImport('positions', 'default');
resolveImport('positions', 'bottom');
resolveImport('positions', 'left');
resolveImport('positions', 'position');
resolveImport('positions', 'right');
resolveImport('positions', 'top');
resolveImport('positions', 'zIndex');
resolveImport('responsivePropType', 'default');
resolveImport('shadows', 'default');
resolveImport('sizing', 'default');
resolveImport('sizing', 'boxSizing');
resolveImport('sizing', 'height');
resolveImport('sizing', 'maxHeight');
resolveImport('sizing', 'maxWidth');
resolveImport('sizing', 'minHeight');
resolveImport('sizing', 'minWidth');
resolveImport('sizing', 'sizeHeight');
resolveImport('sizing', 'sizeWidth');
resolveImport('sizing', 'sizingTransform');
resolveImport('sizing', 'width');
resolveImport('spacing', 'default');
resolveImport('spacing', 'createUnarySpacing');
resolveImport('spacing', 'createUnaryUnit');
resolveImport('spacing', 'getStyleFromPropValue');
resolveImport('spacing', 'getValue');
resolveImport('spacing', 'margin');
resolveImport('spacing', 'marginKeys');
resolveImport('spacing', 'padding');
resolveImport('spacing', 'paddingKeys');
resolveImport('style', 'default');
resolveImport('style', 'getPath');
resolveImport('style', 'getStyleValue');
resolveImport('styled', 'default');
resolveImport('typography', 'default');
resolveImport('typography', 'fontFamily');
resolveImport('typography', 'fontSize');
resolveImport('typography', 'fontStyle');
resolveImport('typography', 'fontWeight');
resolveImport('typography', 'letterSpacing');
resolveImport('typography', 'lineHeight');
resolveImport('typography', 'textAlign');
resolveImport('typography', 'textTransform');
resolveImport('typography', 'typographyVariant');
resolveImport('unstable_getThemeValue', 'default');
resolveImport('unstable_getThemeValue', 'propToStyleFunction');
resolveImport('unstable_getThemeValue', 'styleFunctionMapping');
resolveImport('unstable_memoTheme', 'default');
resolveImport('unstable_styleFunctionSx', 'default');
resolveImport('unstable_styleFunctionSx', 'extendSxProp');
resolveImport('unstable_styleFunctionSx', 'unstable_createStyleFunctionSx');
resolveImport('unstable_styleFunctionSx', 'unstable_defaultSxConfig');
resolveImport('useMediaQuery', 'default');
resolveImport('useMediaQuery', 'unstable_createUseMediaQuery');
resolveImport('useTheme', 'default');
resolveImport('useTheme', 'systemDefaultTheme');
resolveImport('useThemeProps', 'default');
resolveImport('useThemeProps', 'getThemeProps');
resolveImport('useThemeWithoutDefault', 'default');
resolveImport('version', 'default');
resolveImport('version', 'major');
resolveImport('version', 'minor');
resolveImport('version', 'patch');
resolveImport('version', 'prerelease');
resolveImport('version', 'version');